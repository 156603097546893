import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import ContentPage from "../../../layouts/contentPage"

import Seo from "../../../components/seo"

import ProductHero from "../../../components/Product/productHero"
import H1 from "../../../components/h1"
import ButtonColor from "../../../components/buttonColor"
import ProductInfo from "../../../components/Product/productInfo"
import ProductPackshot from "../../../components/Product/productPackshot"
import ProductPackshotBubbles from "../../../components/Product/productPackshotBubbles"
import BubbleBg from "../../../components/Product/bubbleBg"
import ProductSidebar from "../../../components/Product/productSidebar"
import More from "../../../components/more"
import Tabs from "../../../components/Tabs/tabs"
import ProductDescription from "../../../components/Product/productDescription"
import Energyze from "../../../images/media/products/energyze/energyze.svg";
import Ingredients from "../../../components/Product/ingredients"
import Ingredient from "../../../components/Product/ingredient"
import Effectivness from "../../../components/Product/effectivness"
import EffectivnessCard from "../../../components/Product/effectivnessCard"
import ActiveIngredients from "../../../components/Product/activeIngredients"
import ActiveIngredient from "../../../components/Product/activeIngredient"
import DescriptionPackshot from "../../../components/Product/descriptionPackshot"
import Container from "/src/components/Product/container"






const Product = () => (
    <Layout lang={'EN'}>
      <Seo title="Energizing face day serum SPF 15 - Energyze" lang={'EN'} />
        <ProductHero>

            {/* Info */}
            <div data-column className="wrapper">
                <ProductInfo color="lime">
                    <H1>Energizing face day serum SPF 15</H1>
                    <p>To provide energy to tired complexion</p>
                    <ProductPackshot mobile>
                        <StaticImage
                        src="../../images/media/products/energyze/products/energetyzujace-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="Energizing face day serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                    <ul>
                        <li>Vitality and glow revealed. </li>
                        <li>Hydrates to ensure hydrolipid balance. </li>
                        <li>Oxygenates to release more energy in skin cells for balanced microbiom and powerful protective barrier to prevent photoaging processes. </li>
                    </ul>
                    <Container>
                    <ButtonColor as="a" target="_blank" href="https://www.hebe.pl/oxygenetic-energetyzujace-serum-do-twarzy-na-dzien-50-ml-000000000000407509.html" color="lime">Buy in Hebe</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://www.zikodermo.pl/oxygenetic-energyze-energetyzujace-serum-do-twarzy-na-dzien-spf-15-50-ml.html" color="lime">Buy in Ziko Dermo</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://gemini.pl/oxygenetic-energyze-vitamin-c-energetyzujace-serum-do-twarzy-na-dzien-spf-15-50-ml-0107784" color="lime">Buy in Gemini</ButtonColor>
                    </Container>                
                    </ProductInfo>
            </div>

            {/* Packshot */}
            <div data-column>
                <ProductPackshot>
                        <StaticImage
                        src="../../images/media/products/energyze/products/energetyzujace-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="Energizing face day serum SPF 15"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                <More url="#opis-produktu" color="lime" text="more"/>
            </div>

            {/* Category Products */}
            <div data-column>
                <ProductSidebar lang={"EN"}>
                <Link to="/en/products/revitalizing-serum">
                        <StaticImage
                             src="../../images/media/products/energyze/products/rewitalizujace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="nawilzajace-serum"
                        />
                    </Link>
                    <Link to="/en/products/oxygenating-sorbet">
                        <StaticImage
                             src="../../images/media/products/energyze/products/nawilzajacy-sorbet.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="nawilzajace-serum"
                        />
                    </Link>
                    <Link to="/en/products/regenerating-serum">
                        <StaticImage
                            src="../../images/media/products/energyze/products/regeneracyjne-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="regeneracyjne serum"
                        />
                    </Link>
                    <Link to="/en/products/normalizing-serum">
                        <StaticImage
                            src="../../images/media/products/energyze/products/normalizujace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="normalizujace serum"
                        />
                    </Link>
                </ProductSidebar>
            </div>
            {/* <BubbleBg/> */}
        </ProductHero> 
        <section id="opis-produktu">

            {/* TABS */}
            <Tabs color="lime">



                {/* OPIS */}
                <div label="Description" className="tab-list-active">
                <ProductDescription color="lime">
                    <div className="descriptionRow">
                        <div className="column">
                            <div className="text--left">
                                <p><strong>OXYGENETIC ENERGYZE VITAMIN C  Energizing face day serum SPF 15</strong></p>
                                <p>
       
                                        
                                        Energize skin to reveal vitality and glow  due to stimulated skin metabolism. Hydroscopic components protects epidermis from dehydration.
                            
                                </p>
                                <p>
                                    This serum prevents fragmentation of collagen and elastic fibres for super silky smooth texture, supple skin and minimised fine lines.
                                </p>
                                <p>
                                    Protects against pre-mature aging due to UV radition, supplies skin with vitamins and minerals so that it is visibly awaken, revived and nourisehd. With this energizing serum skin will look invigorated, luminous and deeply hydrated.
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <DescriptionPackshot>
                                <StaticImage
                                src="../../images/media/products/energyze/products/energetyzujace-serum_single.png"
                                loading="lazy"
                                width={534}
                                quality={95}
                                placeholder="none"
                                formats={["auto", "webp", "avif"]}
                                alt="Energetyzujące serum"
                             />
                            </DescriptionPackshot>
                        </div>
                        <div className="column">
                            <div className="text--right">
                                <p><strong>How to (use):</strong></p>
                                <p>Cleanse your face and apply this serum in the morning.  For external use only. Avoid contact with eyes.</p>
                                <br/>
                                <p><strong>Volume:</strong></p>
                                <p>50ml</p>
                                <Energyze/>
                            </div>
                        </div>
                    </div>        
                </ProductDescription>
                </div>

                {/* BAZA */}
                <div label="Base">
                <Ingredients>
                    <Ingredient name="Vitamin C" desc="A powerhouse antioxidant which neutralises free radicals harmful to skin cells. Slows aging process in the body and shields against environmental stressors. Lightens pigementation and revives the skin due to enhanced collagen and elastin synthesis." />
                    <Ingredient name="Blend of oxygentaing minerals" desc="Minerals are the component which binds oxygen molecules which are absorbed by the skin and stimulates metabolic processes in the skin so that more energy is released and skin cells start to function properly for healthy and radiant look." />
                    <Ingredient name="Synbiotics" desc="A blend of prebiotics and probiotics which fortify microbiom, our natural protective barrier, to protect against environmental stressors. Hydrate, nourish and smooth your skin." />
                </Ingredients>
                </div>

                {/* SKŁADNIKI AKTYWNE */}
                <div label="Active ingredients">
                <ActiveIngredients>
                    <ActiveIngredient name="Hyaluronic acid" desc="A natural lubricant and a powerful humectant (substance whthat binds to water molecules to retain moisture in the skin and joints for a youthful-looking, plump appearance and structure) reduces the effects og aging by increasing skin elasticity. Hyaluronic acid a staplein your skincare routine.

" />
                    <ActiveIngredient name="Macadamia nut oil" desc="Macadamia nut oil has a chemical profile similar to sebum, i.e. the fatty acid secreted by the skin that makes the skin plump and dewy, which decreases with age. This ingredient repels water causing droplets to form known as hydrophobic which create coating on the skin to ensure moisture, softness and smoothness to enjoy plump and dewy skin." />
                </ActiveIngredients>
                </div>

                {/* SKUTECZNOŚĆ */}
                <div label="Effectiveness">
                <Effectivness>
                    <EffectivnessCard color="lime" percentage="93" desc="badanych potwierdza, że produkt działa szybciej i skuteczniej w stosunku do wcześniej stosowanych preparatów"/>
                    <EffectivnessCard color="lime" percentage="100" desc="badanych potwierdza, że produkt nadaje skórze zdrowy i promienny wygląd"/>
                    <EffectivnessCard color="lime" percentage="97" desc="badanych potwierdza, że produkt opóźnia procesy starzenia się skóry"/>
                </Effectivness>
                </div>
            </Tabs>
        </section>
    </Layout>
)

export default Product
